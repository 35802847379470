import { PAGE_WP_TYPE } from '../contentTypes/page';
import { returnTemplate } from '../lib/_client';
import { returnStaticProps } from '../lib/_helpers';
import SectionBuilder from '../templates/SectionBuilder';

export default function Template(props) {
  return returnTemplate(props, SectionBuilder);
}

export async function getStaticProps(props) {
  props.params = { slug: 'home' };

  return await returnStaticProps({
    ...props,
    type: PAGE_WP_TYPE,
  });
}
